// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker.css';

@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

.w-300 {
  width: 300px !important;
}
.main-footer {
  background-color: white;
  padding: 40px 0px;
  .footer-link {
    color: #000;
    font-size: 13px;
  }
}
.main-min-height {
  min-height: calc(100vh - 184px);
}
.social-box {
  .fab, .fas {
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
    line-height: 31px;
    border-radius: 50%;
    font-size: 15px;
    color: #fff;
  }
  .fa-twitter, .fa-facebook-f {
    background-color: #000;
  }
}
.breadcrumb.breadcrumb-back-none {
  background: none;
}
.btn-outline-orange {
  color: #ed9c26;
  border-color: #ed9c26;

  &:hover, &:focus, &:active {
    background-color: #ed9c26 !important;
    color: #fff !important;
    opacity: 0.8;
  }
}
.btn-outline-blue {
  color: #0c26af;
  border-color: #0c26af;

  &:hover, &:focus, &:active {
    background-color: #0c26af !important;
    color: #fff !important;
    opacity: 0.8;
  }
}
.ml-100 {
  margin-left: 100px;
}

.font-size-25 {
  font-size: 25px !important;
}

@media (min-width: 768px) {
  .ml-md-100 {
    margin-left: 100px !important;
  }
}
